@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Define Alliance No.1 Light */
@font-face {
  font-family: 'AllianceNo1Light';
  src: url('../public/fonts/Degarism\ Studio\ -\ Alliance\ No.1\ Light.otf') format('opentype');
  font-weight: 300; /* Adjust based on the actual weight of Alliance No.1 Light */
  font-style: normal;
}

/* Define Alliance No.2 Light */
@font-face {
  font-family: 'AllianceNo2Light';
  src: url('../public/fonts/Degarism\ Studio\ -\ Alliance\ No.2\ Light.otf') format('opentype');
  font-weight: 300; /* Adjust based on the actual weight of Alliance No.2 Light */
  font-style: normal;
}



@keyframes blink {
  0%, 50% {
    opacity: 1;
  }
  50.1%, 100% {
    opacity: 0;
  }
}
.cursor {
  animation: blink 1s infinite;
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 70px;
  margin-left: 7px;
}

@keyframes grow-line {
  from {
    transform: scaleX(0.5);
  }
  to {
    transform: scaleX(1);
  }
}

.line-grow {
  width: 100%;
  height: 1px;
  background-color: black;
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: left;
  transform: scaleX(0.5);
  animation: grow-line 1s forwards;
}

.progress-ring__background,
.progress-ring__circle {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}


.animate {
  transition: stroke-dashoffset 1s ease-out;
}

.typing-effect-container {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  max-width: 100%;
}

.start-typing {
  animation: typing 3.5s steps(40, end) forwards;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

html {
  scroll-behavior: smooth;
}

.proficiency-fill {
  transition: width 1s ease-in-out;
}

@keyframes surge {
  from {
    transform: translateY(-33%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.label-surge {
  animation: surge 1s ease-out forwards;
}
